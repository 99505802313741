import(/* webpackMode: "eager" */ "/workspace/apps/musiclocal/musiclocal/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/workspace/apps/musiclocal/musiclocal/app/PHProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/apps/musiclocal/musiclocal/app/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProgressProviders"] */ "/workspace/apps/musiclocal/musiclocal/components/ProgressProviders/ProgressProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/workspace/libs/third-party/shadcn-ui/src/components/Toast/Toaster.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/node_modules/.pnpm/next@15.2.4_@babel+core@7.26.10_@playwright+test@1.50.1_babel-plugin-macros@3.1.0_react_50b69f335fddf585b110de40f1fbf8df/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Open_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\",\"weight\":\"500\"}],\"variableName\":\"fontSans\"}");
